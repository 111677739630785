var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Modal", {
    scopedSlots: _vm._u([
      {
        key: "panel-content",
        fn: function() {
          return [
            _c("div", { staticClass: "wrapper" }, [
              _c("h1", [_vm._v("Modifier la livraison")]),
              _vm._v(" "),
              _c("div", { staticClass: "current-offer" }, [
                _c("h2", [_vm._v("Livraison actuelle")]),
                _vm._v(" "),
                _c(
                  "span",
                  [
                    _c("DeliveryOffer", {
                      attrs: { offer: _vm.orderProduct.delivery.delivery_offer }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "offers" },
                [
                  _c("h2", [_vm._v("Nouvelle livraison")]),
                  _vm._v(" "),
                  _c("DeliveryOffers", {
                    attrs: { product: _vm.product, quantity: _vm.quantity },
                    scopedSlots: _vm._u([
                      {
                        key: "offer",
                        fn: function(data) {
                          return [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.selected,
                                  expression: "form.selected"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                id: data.offer.legacyDeliveryOfferId
                              },
                              domProps: {
                                value: data.offer.legacyDeliveryOfferId,
                                checked: _vm._q(
                                  _vm.form.selected,
                                  data.offer.legacyDeliveryOfferId
                                )
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.form,
                                    "selected",
                                    data.offer.legacyDeliveryOfferId
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                attrs: { for: data.offer.legacyDeliveryOfferId }
                              },
                              [
                                _c("DeliveryOffer", {
                                  attrs: { offer: data.offer }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "helpers" }, [
                _c("span", [
                  _vm._v("Vous pouvez si besoin "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/#!/moderation/products/edit/" + _vm.product.id
                      }
                    },
                    [_vm._v("ajouter une option de livraison")]
                  ),
                  _vm._v(" sur le produit")
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "infos" },
                _vm._l(_vm.infos, function(info, key) {
                  return _c("span", { key: key }, [_vm._v(_vm._s(info))])
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "actions" },
                [
                  _c(
                    "DangerousButton",
                    {
                      attrs: { disabled: !_vm.form.selected },
                      on: { click: _vm.confirm }
                    },
                    [_vm._v("Confirmer la modification")]
                  )
                ],
                1
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }