<template>
  <span>
    <span>{{ offer.label }} :</span>
    <span>{{ offer.totalPrice.amount }} {{ offer.totalPrice.currency }}</span>
  </span>
</template>

<script>
export default {
  props: {
    offer: {
      type: Object,
      required: true,
    }
  },
}
</script>
