<template>
    <div>
        <div class="row">
            <div class="col-xs-12 col-md-6">
                <h4>Montant à rembourser</h4>
            </div>
            <div class="col-xs-12 col-md-6">
                <h4>Montant à demander</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-md-6">
                <div>
                    <p class="label inline-block">
                        Acheteur
                    </p>
                    <p class="label inline-block price-right">
                        {{this.form.refund_buyer}} €
                    </p>
                </div>
            </div>
            <div class="col-xs-12 col-md-6">
                <div>
                    <p class="label inline-block">
                        Acheteur
                    </p>
                    <p class="label inline-block price-right">
                        {{this.form.ask_buyer}} €
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-md-6">
                <div class="bg-color-grey">
                    <p class="label inline-block">
                        Vendeur
                    </p>
                    <p class="label inline-block price-right">
                        {{this.form.refund_seller}} €
                    </p>
                </div>
            </div>
            <div class="col-xs-12 col-md-6">
                <div class="bg-color-grey">
                    <p class="label inline-block">
                        Vendeur
                    </p>
                    <p class="label inline-block price-right">
                        {{this.form.ask_seller}} €
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-md-6">
                <div class="bg-color-grey">
                    <p class="label inline-block">
                        Selency
                    </p>
                    <p class="label inline-block price-right">
                        {{this.form.refund_marketplace}} €
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            // orderProduct
            entity: {
                type: Object
            },

            // new DeliveryOffer
            newDeliveryEntity: {
                type: Object
            },
            deliveryFrom: {
                type: Boolean
            },
            newDeliveryFrom: {
                type: Boolean
            }
        },

        data() {
            return {
                form: {
                    refund_seller: null,
                    refund_buyer: null,
                    refund_marketplace: null,
                    ask_buyer: null,
                    ask_seller: null,
                },
                refunds: {},
            };
        },

        mounted() {
            this.fillData()
        },

        methods: {
            isNewPriceDeliveryHigher() {
                return this.getPriceDelivery() < this.getNewPriceDelivery()
            },

            getPriceDelivery() {
                return this.entity.delivery.delivery_offer.price.value
            },

            getRefundMountSeller() {
                if(this.isCocolisProvider()) {
                    return 0
                }
                if(!this.isDeliveryMarketPlace()) {
                    if(!this.isNewPriceDeliveryHigher()) {
                        return 0 - (this.getPriceDelivery() - this.getNewPriceDelivery())
                    }
                }

                return 0
            },

            getRefundMountBuyer() {
                if(this.isDeliveryMarketPlace()) {
                    if(!this.isNewPriceDeliveryHigher()) {
                        if(this.isHandling()) {
                            var total = this.getPriceDelivery() + this.getHandling()
                            return Math.abs(this.getNewPriceDelivery() - total)
                        }
                        return (this.getPriceDelivery() + this.getDeliveryPriceTurbo()) - this.getDeliveryPriceTurbo() - this.getNewPriceDelivery()
                    }

                    return 0
                } else {
                    if(this.isNewPriceDeliveryHigher()) {
                        return 0
                    }
                }

                return this.getPriceDelivery() - this.getNewPriceDelivery()
            },

            getRefundMountMarketPlace() {
                if(this.isCocolisProvider() && !this.isNewPriceDeliveryHigher()) {
                    return 0 - (this.getPriceDelivery() - this.getNewPriceDelivery())
                }

                if(!this.isDeliveryMarketPlace() && !this.isNewDeliveryMarketPlace()) {
                    return 0
                }

                if(this.isNewPriceDeliveryHigher()) {
                   return 0
                }

                if(this.isHandling()) {
                    var total = this.getPriceDelivery() + this.getHandling()
                    return this.getNewPriceDelivery() - total
                }

                return 0 - ((this.getPriceDelivery() + this.getDeliveryPriceTurbo()) - this.getDeliveryPriceTurbo() - this.getNewPriceDelivery())
            },

            getAskMountSeller() {
                if(this.isNewPriceDeliveryHigher()) {
                    return 0
                }

                return 0
            },

            getAskMountBuyer() {
                if(this.isDeliveryMarketPlace() && this.isHandling() && this.isNewPriceDeliveryHigher()) {
                    var total = this.getPriceDelivery() + this.getHandling()

                    return this.getNewPriceDelivery() - total
                }

                if(this.isNewPriceDeliveryHigher()) {
                    return this.getNewPriceDelivery() - ((this.getPriceDelivery() + this.getDeliveryPriceTurbo()) - this.getDeliveryPriceTurbo())
                }

                return 0
            },

            isTurboActivated() {
                return this.entity.delivery.delivery_offer.booster
            },

            getDeliveryPriceTurbo() {
                if(this.isTurboActivated()) {
                    return this.entity.delivery.delivery_offer.booster.price.value
                }

                return 0
            },

            isDeliveryMarketPlace() {
                return this.deliveryFrom
            },

            isNewDeliveryMarketPlace() {
                return this.newDeliveryFrom
            },

            isCocolisProvider() {
                return 'COCOLIS' === this.entity.delivery.delivery_offer.provider_type
            },

            getNewPriceDelivery() {
                return this.newDeliveryEntity.totalPrice.amount / 100
            },

            isHandling() {
                var data = false
                if(this.entity.order_services && this.entity.order_services.length > 0) {
                    this.entity.order_services.forEach(function (element) {
                        if(element.delivery_service.name === 'HANDLING') {
                            data = true
                        }
                    })
                }

                return data
            },

            getHandling() {
                var data = 0
                if(this.entity.order_services && this.entity.order_services.length > 0) {
                    this.entity.order_services.forEach(function (element) {
                        if(element.delivery_service.name === 'HANDLING') {
                            data = element.price.value
                        }
                    })
                }

                return data
            },

            fillData() {
                this.form.refund_seller = this.getRefundMountSeller()
                this.form.refund_buyer = this.getRefundMountBuyer()
                this.form.refund_marketplace = this.getRefundMountMarketPlace()
                this.form.ask_buyer = this.getAskMountBuyer()
                this.form.ask_seller = this.getAskMountSeller()

                this.$emit('input', this.form)
            }
        },

        watch: {
            newDeliveryEntity() {
                this.fillData()
            }
        },
    }
</script>

<style scoped>
.inline-block {
    display: inline-block;
}

.label {
    padding: 10px;
    margin: 2px;
}
</style>
