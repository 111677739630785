<template>
    <div>
        <h2 class="text-center">Ajouter une option de livraison</h2>
        <div>
            <h4>
                Type de livraison
            </h4>
            <div class="row">
                <div class="col-xs-12 col-md-6">
                    <select v-model="form.selected" name="delivery-option" id="delivery-option">
                        <option value="">--Please choose an option--</option>
                        <option v-for="(offer, index) in offers" :key="index" :value="index">
                            {{ offer }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div v-if="this.form.selected === 'PERSONAL_DELIVERY'">
            <PersonalDelivery :product="this.product" v-model="personalDelivery"></PersonalDelivery>
        </div>
        <div v-if="this.form.selected === 'COLISSIMO'">
            <Colissimo v-model="colissimo"></Colissimo>
        </div>
        <div v-if="this.form.selected === 'BROCANTE_LAB'">
            <Brocantelab v-model="brocantelab"></Brocantelab>
        </div>
        <div v-if="this.form.selected === 'QUOTATION' || this.form.selected === 'CUSTOM'">
            <Quotation v-model="quotation"></Quotation>
        </div>
        <div class="row">
            <div class="col-xs-12 col-md-6"></div>
            <div class="col-xs-12 col-md-6">
                <button class="button-primary" @click="cancel">Annuler</button>
                <DangerousButton :disabled="!this.form.selected" class="button-primary-green" @click="confirm">Ajouter l'option</DangerousButton>
            </div>
        </div>
    </div>
</template>

<script>
import * as monolithClient from '../../api/monolith'
import PersonalDelivery from "./PersonalDelivery.vue";
import Quotation from "./Quotation.vue";
import Colissimo from "./Colissimo.vue";
import Brocantelab from "./Brocantelab.vue";

const predefinedDistances = [
    { label: '- 15 km', value: { min: null, max: 15 } },
    { label: '15 - 100 km', value: {min:15, max: 100} },
    { label: '+ 100 km', value:{min:100, max: null} }
];

const deliveryOptions = {
    "BROCANTE_LAB": "Livraison personnalisée par Selency (argent pour Selency)",
    "WITHDRAWAL_AT_HOME": "Retrait chez le vendeur (argent pour le vendeur)",
    "PERSONAL_DELIVERY": "Livraison par le vendeur (argent pour le vendeur)",
    "COLISSIMO": "Livraison Colissimo (argent pour le vendeur)",
    "CUSTOM": "Livraison personnalisée (argent pour le vendeur)",
}

export default {
  components: {PersonalDelivery, Quotation, Colissimo, Brocantelab},
  props: {
    product: {
      type: Object,
      required: true,
    },
    orderProduct: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deliveryOfferId: null,
      offers: [],
      personalDelivery: [],
      quotation: [],
      colissimo: [],
      brocantelab: [],
      form: {
        selected: null,
      },
    };
  },

  methods: {
    cancel() {
        this.$emit('delivery-offer-id', null);
    },

    async confirm() {
      let deliveryOptionSetId = this.product.delivery_option_set.id;
      if (!deliveryOptionSetId) {
        deliveryOptionSetId = this.product.delivery_option_set;
      }

      if(this.form.selected === 'WITHDRAWAL_AT_HOME') {
        try {
          const data = {
            'deliveryOptionSetId': deliveryOptionSetId,
            'providerType': this.form.selected,
            'body': {
              'price': {
                'amount' : 0,
                'currency' : 'EUR'
              },
            }
          }

          const response = await monolithClient.productDeliveryOptionCreate(this.product.id, data)
          this.$emit('delivery-offer-id', response.item.id);
        } catch (e) {
          console.log("ERROR Add new delivery Option")
        }
      }
      else if(this.form.selected === 'PERSONAL_DELIVERY') {
        var minDistance, maxDistance = null

          predefinedDistances.forEach(function (element) {
              if(element.label === this.personalDelivery.distance) {
                  maxDistance = element.value.max
                  minDistance = element.value.min
              }
            }, this
          );

        var country = null
        if(Object.prototype.hasOwnProperty.call(this.personalDelivery, "placeResultData") && null !== this.personalDelivery.placeResultData) {
            if(Object.prototype.hasOwnProperty.call(this.personalDelivery, 'address_components') && null !== this.personalDelivery.placeResultData.address_components) {
              country = this.personalDelivery.placeResultData.address_components.filter(function(component){
                return component.types.indexOf('country') > -1;
              })[0].short_name;
            }
        }

        const data = {
           'deliveryOptionSetId': deliveryOptionSetId,
           'providerType': this.form.selected,
           'body': {
             'price': {
               'amount': Number.parseInt(this.personalDelivery.price * 100, 10),
               'currency': 'EUR'
             },
             'minDistance': minDistance,
             'maxDistance': maxDistance,
             'delay': this.personalDelivery.delay,
             'gmapsDestinationComponents':
               [{
                 'type': 'locality',
                 'value': Object.prototype.hasOwnProperty.call(this.personalDelivery, "placeResultData") && null !== this.personalDelivery.placeResultData ? this.personalDelivery.placeResultData.name : null,
                 'country': country,
                 'placeId': Object.prototype.hasOwnProperty.call(this.personalDelivery, "placeResultData") && null !== this.personalDelivery.placeResultData ? this.personalDelivery.placeResultData.place_id : null,
               }]
           },
        };

        const response = await monolithClient.productDeliveryOptionCreate(this.product.id, data)
        this.$emit('delivery-offer-id', response.item.id);
      }
      else if(this.form.selected === 'COLISSIMO') {
        const data = {
           'deliveryOptionSetId': deliveryOptionSetId,
           'providerType': this.form.selected,
           'body': {
             'price': {
               'amount': Number.parseInt(this.colissimo.price * 100, 10),
               'currency': 'EUR'
             },
           'title': this.colissimo.title,
           'destinationCountry': this.colissimo.country,
           'destinationContinent': this.colissimo.continent,
           'destinationRegion': this.colissimo.region,
           },
        };
        if(this.colissimo.confirmedDeliveryInternational) {
            data.destinationCountry = null
            data.destinationContinent = null
            data.destinationRegion = null
        }

        const response = await monolithClient.productDeliveryOptionCreate(this.product.id, data)
        this.$emit('delivery-offer-id', response.item.id);
      }
      else if(this.form.selected === 'BROCANTE_LAB') {
        if(this.brocantelab.choice === 'personal_delivery') {
          const data = {
            'deliveryOptionSetId': deliveryOptionSetId,
            'providerType': this.form.selected,
            'body': {
                'price': {
                    'amount': Number.parseInt(this.brocantelab.price * 100, 10),
                    'currency': 'EUR'
                },
                'title': this.brocantelab.title,
                'destinationCountry': this.brocantelab.country,
                'destinationContinent': this.brocantelab.continent,
                'destinationRegion': this.brocantelab.region,
            },
          };
          const response = await monolithClient.productDeliveryOptionCreate(this.product.id, data)
          this.$emit('delivery-offer-id', response.item.id);
        } else {
          await monolithClient.productDeliveryOptionActivateBrocanteLabGrid(this.product.id, deliveryOptionSetId)
        }
      }
      else if(this.form.selected === 'CUSTOM') {
        const data = {
           'deliveryOptionSetId': deliveryOptionSetId,
           'providerType': this.form.selected,
           'body': {
             'price': {
               'amount': Number.parseInt(this.quotation.price * 100, 10),
               'currency': 'EUR'
             },
             'title': this.quotation.title,
             'destinationCountry': this.quotation.country,
             'destinationContinent': this.quotation.continent,
             'destinationRegion': this.quotation.region,
           },
        };
        const response = await monolithClient.productDeliveryOptionCreate(this.product.id, data)
        this.$emit('delivery-offer-id', response.item.id);
      }
    }
  },

  mounted() {
    this.offers = deliveryOptions;
  },
}
</script>

<style scoped>
.margin-top-label {
    margin-top: 10px;
}
</style>
