<template>
  <Modal>
    <template v-slot:panel-content>

      <div v-if="!getAddDelivery()">
        <h2 class="text-center">Modifier la livraison</h2>
      <div v-if="'COCOLIS' === getProviderType()">
        <p style="color: red">
          Vous êtes sur le point de modifier une livraison COCOLIS. <br>

          N'oubliez pas de retirer l'annonce sur le site <a href="https://www.cocolis.fr/" target="_blank">cocolis.fr</a>  si vous validez la modification de livraison !
        </p>
      </div>
        <div class="row margin-top-update-delivery">
          <div class="col-xs-12 col-md-6">
            <h4>
              Livraison actuelle
            </h4>
          </div>
          <div class="col-xs-12 col-md-6">
            <div class="row">
              <div class="col-xs-12 col-md-6">
                <h4>
                  Sélectionner
                </h4>
              </div>
              <div class="col-xs-12 col-md-6">
                <div class="add-delivery" @click="displayAddDelivery()">
                  Ajouter une option de livraison
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row margin-top-update-delivery">
          <div class="col-xs-12 col-md-6" v-if="isTurboActivated()">
            <div class="bg-color-grey">
              <p class="label inline-block">
                <DeliveryTitle :title=getProviderType()></DeliveryTitle>
              </p>
              <p class="label inline-block price-right">
                {{ getPriceDelivery() }} €
              </p>
              <p class="label inline-block price-right line-through">
                {{ getPricewithTurboActivated() }} €
              </p>
            </div>
          </div>
          <div class="col-xs-12 col-md-6" v-else>
            <div class="bg-color-grey">
              <p class="label inline-block">
                <DeliveryTitle :title=getProviderType()></DeliveryTitle>
              </p>
              <p class="label inline-block price-right">
                {{ getPriceDelivery() }} €
              </p>
            </div>
          </div>
          <div class="col-xs-12 col-md-6">
            <div>
              <OrderDeliveryOffers :key="`test-${deliveryOfferId}`" :product="product" :deliveryOfferId="deliveryOfferId" :quantity="quantity" v-on:optionSelected="optionSelectedReceived">
                <template v-slot:offer="data">
                    <DeliveryOffer :offer="data.offer"></DeliveryOffer>
                </template>
              </OrderDeliveryOffers>
            </div>
          </div>
        </div>

        <div class="row margin-top-update-delivery">
          <div class="col-xs-12 col-md-6">
            <div class="bg-color-grey">
              <p class="label inline-block">
                Turbo
              </p>
              <p class="label inline-block price-right">
                {{ getDeliveryPriceTurbo() }} €
              </p>
            </div>
          </div>
          <div class="col-xs-12 col-md-6" v-if="getOptionDeliverySelected()">
            <div class="bg-color-grey">
              <p class="label inline-block">
                Turbo (après changement de livraison)
              </p>
              <p class="label inline-block price-right">
                {{ getBoosterFromNewDelivery() }} €
              </p>
            </div>
          </div>
        </div>
        <div class="row margin-top-update-delivery" v-if="orderProduct.order_services.length > 0">
          <div class="col-xs-12 col-md-6">
            <div class="bg-color-grey">
              <p class="label inline-block">
                <OrderService
                      v-for="service in orderProduct.order_services"
                      :key="service.id"
                      :service="service"></OrderService>
              </p>
            </div>
          </div>
        </div>
        <div class="row margin-top-update-delivery" v-else>
          <div class="col-xs-12 col-md-6">
            <div class="bg-color-grey">
              <p class="label inline-block">
                Livraison à l'étage
              </p>
                <p class="label inline-block price-right">
                    0 €
                </p>
            </div>
          </div>
        </div>
        <SplitOrderTable :entity="orderProduct" :deliveryFrom="isDeliveryMarketPlace()" v-if="!getOptionDeliverySelected()"></SplitOrderTable>
        <OrderRefund :entity="orderProduct"
                     :newDeliveryEntity="getOptionDeliverySelected()"
                     :deliveryFrom="isDeliveryMarketPlace()"
                     :newDeliveryFrom="isNewDeliveryByMarketPlace()"
                     v-if="getOptionDeliverySelected()"
                     v-model="refunds"
        ></OrderRefund>
        <SplitCompareOrderTable :entity="orderProduct"
                                :newDeliveryEntity="getOptionDeliverySelected()"
                                :deliveryFrom="isDeliveryMarketPlace()"
                                :newDeliveryFrom="isNewDeliveryByMarketPlace()"
                                v-if="getOptionDeliverySelected()"
                                v-model="splits"
        ></SplitCompareOrderTable>
      </div>
      <div v-if="getAddDelivery()">
        <AddDelivery v-on:delivery-offer-id="deliveryOfferIdAdded" :product="product" :orderProduct=orderProduct></AddDelivery>
      </div>

      <div class="line-border-bottom" v-if="getOptionDeliverySelected() && !getAddDelivery()"></div>

      <form @submit="e => e.preventDefault()" v-if="getOptionDeliverySelected() && !getAddDelivery()">
        <h4>
          Notifications
        </h4>
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <div>
              <input type="checkbox" id="notification_email_buyer_refund" value="notification_email_buyer_refund" v-model="notifications">
              <label for="notification_email_buyer_refund">Notifier l'acheteur du remboursement par email</label>
            </div>
            <div>
              <input type="checkbox" id="notification_email_seller" value="notification_email_seller" v-model="notification_email_seller">
              <label for="notification_email_seller">Notifier le vendeur par email</label>
            </div>
            <div>
              <input type="checkbox" id="notification_email_buyer" value="notification_email_buyer" v-model="notification_email_buyer">
              <label for="notification_email_buyer">Notifier l'acheteur par email</label>
            </div>

<!--            <div>-->
<!--              <input type="checkbox" id="notification_email_logistic" value="notification_email_logistic" v-model="notifications">-->
<!--              <label for="notification_email_logistic">Notifier la logistique</label>-->
<!--            </div>-->
<!--            <div>-->
<!--              <input type="checkbox" id="notification_email_admin" value="notification_email_admin" v-model="notifications">-->
<!--              <label for="notification_email_admin">Notifier l'admin</label>-->
<!--            </div>-->
          </div>
          <div class="col-xs-12 col-md-6">
            <div>
                <input type="checkbox" v-model="proceed_refund">
                <label>Procéder au remboursement de l'acheteur</label>
            </div>
            <div>
                <input type="checkbox" v-model="confirm_update_delivery">
                <label style="color: #4B6965">J'ai bien lu et relu, je suis prêt.e, à confirmer definitivement le changement de livraison</label>
            </div>
            <div>
              <div class="row">
                <div class="col-xs-12 col-md-6">
                  <button class="button-primary" @click="close">
                    <span>Annuler</span>
                  </button>
                </div>
                <div class="col-xs-12 col-md-6">
                  <DangerousButton class="button-primary-green" :disabled="!confirm_update_delivery" @click="confirm">
                    <span>Confirmer</span>
                  </DangerousButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<style scoped>
form {
  margin-top: 20px;
}

.label {
  padding: 10px;
  margin: 2px;
}

.line-border-bottom {
  margin-top: 20px;
  border-bottom: 1px solid #9F9F9F
}

.inline-block {
  display: inline-block;
}

.line-through {
  text-decoration: line-through;
}

.add-delivery {
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
  font-size: 15px;
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
</style>

<script>
import * as sales from '../../api/sales';
import * as orders from '../../api/orders';
import { getCurrentUser } from '../../auth';
import SplitOrderTable from './SplitOrderTable.vue';
import SplitCompareOrderTable from './SplitCompareOrderTable.vue';
import OrderRefund from './OrderRefund.vue';
import OrderDeliveryOffers from '../../core/delivery/OrderDeliveryOffers.vue';
import DeliveryTitle from '../../core/DeliveryTitle.vue';
import OrderService from '../../core/OrderService.vue';
import AddDelivery from './AddDelivery.vue';

export default {
  props: {
    orderProduct: {
      type: Object
    },
  },

  data() {
    return {
      deliveryOfferId: null,
      optionSelected: null,
      refunds: {},
      splits: {},
      notifications: [],
      notification_email_seller: false,
      notification_email_buyer: false,
      confirm_update_delivery: false,
      proceed_refund: false,
      addDelivery: false
    };
  },

  methods: {
    deliveryOfferIdAdded(id) {
      this.deliveryOfferId = id
      this.addDelivery = false
    },
    optionSelectedReceived(value) {
      this.optionSelected = value
    },

    getDeliveryPriceTurbo() {
      if(this.isTurboActivated()) {
        return this.orderProduct.delivery.delivery_offer.booster.price.value
      }

      return 0
    },

    isDeliveryMarketPlace() {
      let providerType = this.orderProduct.delivery.delivery_offer.provider_type

      if(providerType === 'BROCANTE_LAB' || providerType === 'RETAIL') {
        return true
      }

      if(providerType === 'COLISSIMO') {
        if(this.orderProduct.delivery.delivery_offer.auto_price !== null && true === this.orderProduct.delivery.delivery_offer.auto_price) {
          return true
        }
      }

      return false
    },

    isNewDeliveryByMarketPlace() {
      let providerType = this.optionSelected.providertype

      return ['by_selency', 'retail','mondial_relay'].includes(providerType)
    },

    getPriceDelivery() {
      return this.orderProduct.delivery.delivery_offer.price.value
    },

    getPricewithTurboActivated() {
      if(this.isTurboActivated()) {
        return this.getPriceDelivery() + this.getDeliveryPriceTurbo()
      }
    },

    getProviderType() {
      return this.orderProduct.delivery.delivery_offer.provider_type
    },

    isTurboActivated() {
      return this.orderProduct.delivery.delivery_offer.booster
    },

    getOptionDeliverySelected() {
      return this.optionSelected
    },

    displayAddDelivery() {
      this.addDelivery = true
    },

    getAddDelivery() {
      return this.addDelivery
    },

    getBoosterFromNewDelivery() {
      if(this.getOptionDeliverySelected()) {
        if(this.optionSelected.booster !== undefined && this.optionSelected.booster !== null) {
          return this.optionSelected.booster.amount
        }

        return 0
      }
    },

    close() {
      this.$root.$emit('modal.close');
    },

    async confirm() {
      let deliveryId = null
      let comment = JSON.stringify({
        comment: 'Changement de livraison (Vendeur)',
        type: 'VENDEUR',
        ops_user: this.user.user_id,
        ops_name: this.user.firstname + ' .' + (this.user.lastname[0] || ''),
      });

      try {
        const dataDeliveryOfferId = {
          deliveryOfferId: this.optionSelected.legacyDeliveryOfferId,
          notificationEmailSeller: this.notification_email_seller,
          notificationEmailBuyer: this.notification_email_buyer,
        };

        const response = await sales.updateOrderProduct(this.orderProduct.id, dataDeliveryOfferId);
        deliveryId = response.data.delivery.id
      } catch (e) {
        console.log('error update delivery')
        return null
      }

      try {
        const dataSplit = {
          delivery_seller_split: Math.abs(this.splits.new_split_seller) + Math.abs(this.refunds.refund_seller),
          delivery_marketplace_split: Math.abs(this.splits.new_split_marketplace) + Math.abs(this.refunds.refund_marketplace),
        };

         await sales.updateSplits(this.orderProduct.id, dataSplit)

      } catch (e) {
        console.log('error split')
      }

      try {
        const data = {
          orderProducts: [],
          deliveries: [],
          orderServices: [],
        };

        if (this.proceed_refund && this.refunds) {
          data.deliveries.push({
            currentCount: 0,
            id: deliveryId,
            comment: comment,
            split: {
              seller: {
                value: Math.abs(this.refunds.refund_seller),
                currency: 'EUR',
              },
              marketplace: {
                value: Math.abs(this.refunds.refund_marketplace),
                currency: 'EUR',
              },
            },
            toNotify: this.notifications,
          });
        }

        await orders.createRefund(this.orderProduct.order_id, data)
      } catch (e) {
        console.log('error refund delivery')
      }

      console.log('all OK')
      window.location.reload();
    }
  },

  computed: {
    product() {
      return this.orderProduct.product;
    },
    user() {
      return getCurrentUser();
    },
    quantity() {
      return this.orderProduct.quantity ? this.orderProduct.quantity : 1;
    },
  },

  components: {
    SplitOrderTable,
    OrderDeliveryOffers,
    OrderRefund,
    SplitCompareOrderTable,
    DeliveryTitle,
    OrderService,
    AddDelivery
  },
}
</script>
