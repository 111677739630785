<template>
  <div>
    <span>{{provider}}</span>
    <!-- <Tip v-if="tip && info" :text="info"></Tip> -->
    <template v-if="price">
      <br>
      <Price :price="delivery.delivery_offer.price"></Price>
    </template>
  </div>
</template>

<script>
const providers = {
  'BROCANTE_LAB': {
    title: "Livraison par Selency",
    info: "Argent pour Selency",
  },
  'PERSONAL_DELIVERY': {
    title: "Livraison par le vendeur",
    info: "Argent pour le vendeur"
  },
  'WITHDRAWAL_AT_HOME': {
    title: "Retrait chez le vendeur",
    info: "Gratuit",
  },
  'COLISSIMO': {
    title: "Colissimo",
    title_psa: "Colissimo (PSA)",
  },
  'MONDIAL_RELAY': {
    title: "Mondial Relay",
    info: "Argent pour Selency",
  },
  'QUOTATION': {
    title: "Devis",
    info: "Argent pour le vendeur",
  },
  'CUSTOM': {
    title: "Personnalisée",
    info: "Argent pour le vendeur"
  },
};

export default {
  props: {
    delivery: {
      type: Object,
      required: true,
    },
    price: {
      type: Boolean,
      required: false,
      default: true,
    },
    tip: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    providerType() {
      return this.delivery.delivery_offer.provider_type
        || this.delivery.delivery_offer.delivery_option ? this.delivery.delivery_offer.delivery_option.provider_type : '';
    },

    provider() {
      const providerType = this.providerType;

      if (providerType === 'COLISSIMO' && this.delivery.delivery_offer.auto_price) {
        return providers[providerType].title_psa;
      }

      return providers[providerType] ? providers[providerType].title : providerType;
    },

    info() {
      const providerType = this.providerType;

      if (providers[providerType]) {
        return providers[providerType].info;
      }
    }
  }
}
</script>
