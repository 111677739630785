<template>
  <div>
    <div v-for="(offer, index) in offers" :key="index" >
      <slot name="offer" :offer="offer"></slot>
    </div>
  </div>
</template>

<script>
import * as monolith from '../../api/monolith';

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    quantity: {
      type: Number,
      required: true,
    }
  },

  data() {
    return {
      offers: [],
    };
  },

  computed: {
    params() {
      return {
        shippingAddressPostcode: '75001',
        shippingAddressCountry: 'FR',
        quantity: this.quantity,
      };
    }
  },

  mounted() {
    monolith
    .getUsableDeliveryOptions(this.product.id, this.params)
    .then(data => {
      this.offers = data;
    });
  }
}
</script>
