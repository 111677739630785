<template>
  <div>
    <div>
      <select v-model="form.selected" style="padding: 5px 0; height: 47px" @change="optionSelected()">
        <option value="">Sélectionner une nouvelle livraison</option>
        <option v-for="(offer, index) in offers" :key="index" :value="offer">
          <slot name="offer" :offer="offer"></slot>
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import * as monolith from '../../api/monolith';

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    deliveryOfferId: {
      type: String,
    },
    quantity: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      offers: [],
      form: {
        selected: null,
      }
    };
  },

  methods: {
    optionSelected() {
      if(this.form.selected) {
        this.$emit('optionSelected', this.form.selected)
      } else {
        this.$emit('optionSelected', null)
      }
    },
  },

  computed: {
    params() {
      return {
        shippingAddressPostcode: '75001',
        shippingAddressCountry: 'FR',
        quantity: this.quantity,
      };
    }
  },

  mounted() {
    monolith
    .getUsableDeliveryOptions(this.product.id, this.params)
    .then(data => {
      this.offers = data;
      this.offers.forEach(function (offer) {
        if(offer.legacyDeliveryOfferId === this.deliveryOfferId) {
          this.form.selected = offer
        }}
      , this);
    });
  }
}
</script>
